import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode"; //  使用具名匯出
import { useRouter } from "vue-router";
import { ref, computed, watch } from "vue";
import { useHead } from "@vueuse/head"; 
import { useRoute } from 'vue-router'; // ✅ 加上這一行


export default {
  name: "App",
  
  setup() {
    const mobileMenuOpen = ref(false);
    const socialDropdownOpen = ref(false);
    const router = useRouter(); // vue-router 導航用
    const route = useRoute();

    const toggleMobileMenu = () => {
      mobileMenuOpen.value = !mobileMenuOpen.value;
      if (mobileMenuOpen.value === false) {
        socialDropdownOpen.value = false;
      }
    };

    const closeMobileMenu = () => {
      mobileMenuOpen.value = false;
      socialDropdownOpen.value = false;
    };

    const toggleSocialDropdown = () => {
      socialDropdownOpen.value = !socialDropdownOpen.value;
    };

    // 判斷是否有登入
    const token = Cookies.get("token");

    const isLoggedIn = computed(() => !!token); // 有 token 表示登入中

    const isAdmin = computed(() => {
      const token = Cookies.get("token");
      if (!token) return false;

      try {
        const decoded = jwtDecode(token);
        return decoded.admin === "true"; // 後端傳的是字串，要判斷 "true"
      } catch (error) {
        return false;
      }
    });

    // 登出方法
    const logout = () => {
      Cookies.remove("token"); // 移除 token
      router.push("/login").then(() => {
        location.reload(); // 導航後刷新頁面
      });
    };

    watch(
      () => route.path,
      () => {
        const routeName = route.name;
        let title = "祐強醫療儀器有限公司｜YoChung Medical Instrument Co., Ltd.";
        let description = "祐強醫療儀器，提供高品質醫療設備與技術支援，服務全台醫療院所。";

        if (routeName === "about") {
          title = "關於祐強 - YoChung Medical Instrument Co., Ltd.";
          description = "了解祐強醫療儀器的發展歷程與經營理念。";
        } else if (routeName === "products") {
          title = "商品專區 - YoChung Medical Instrument Co., Ltd.";
          description = "探索我們提供的各式醫療設備與產品資訊。";
        } else if (routeName === "contact") {
          title = "聯絡我們 - YoChung Medical Instrument Co., Ltd.";
          description = "聯絡祐強醫療儀器，我們提供專業諮詢與售後服務。";
        }

        useHead({
          title,
          meta: [
            { name: "description", content: description },
            { property: "og:title", content: title },
            { property: "og:description", content: description }
          ]
        });
      },
      { immediate: true }
    );

    return {
      mobileMenuOpen,
      socialDropdownOpen,
      toggleMobileMenu,
      closeMobileMenu,
      toggleSocialDropdown,
      isLoggedIn,
      isAdmin,
      logout,
    };
  },
};
