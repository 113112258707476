import { createApp } from 'vue'
import { createHead } from '@vueuse/head';
import App from './App.vue'
import router from './router'

// 如果您有使用 Vuex 或 Pinia 等狀態管理庫，也可以在這裡引入
// import store from './store'

const app = createApp(App)
const head = createHead(); 
app.use(router)
app.use(head); 
// 如果有使用 Vuex: app.use(store)
app.mount('#app')