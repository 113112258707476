import { createRouter, createWebHistory } from "vue-router";
import Cookies from "js-cookie"; // ✅ 引入 cookie 工具
import HomeView from "@/components/HomePage.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    // 使用懶加載減少初始加載時間
    component: () => import("@/components/AboutPage.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/components/LoginPage.vue"),
    meta: { hideFooter: true } // 添加這個元數據
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("@/components/ForgotPage.vue"),
    meta: { hideFooter: true } // 添加這個元數據
  },
  {
    path: "/reset",
    name: "reset",
    component: () => import("@/components/ResetPage.vue"),
    meta: { hideFooter: true } // 添加這個元數據
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/components/RegisterPage.vue"),
    meta: { hideFooter: true } // 添加這個元數據
  },
    {
      path: '/products',
      name: 'products',
      component: () => import('@/components/ProductsPage.vue')
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('@/components/ContactPage.vue'),
      meta: { hideFooter: true } // 添加這個元數據
    },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('@/components/CartPage.vue')
  },
  {
    path: '/payment',
    name: 'Payment',
    component: () => import('@/components/PaymentResult.vue'),
    meta: { requiresAuth: true } // 需要身份驗證
  },
  {
    path: "/location",
    name: "location",
    component: () => import("@/components/LocationPage.vue"),
  },
 // 會員中心路由
{
  path: "/member",
  name: "member",
  component: () => import("@/components/member/MemberLayout.vue"),
  meta: { requiresAuth: true, hideHeader: true, hideFooter: true },
  children: [
    {
      path: "",
      name: "member-dashboard",
      component: () => import("@/components/member/MemberDashboard.vue")
    },
    {
      path: "profile",
      name: "member-profile",
      component: () => import("@/components/member/MemberProfile.vue")
    },
    {
      path: "orders",
      name: "member-orders",
      component: () => import("@/components/member/MemberOrders.vue")
    },

  ]
},
{
  path: "/admin",
  name: "admin",
  component: () => import("@/components/admin/AdminLayout.vue"),
  meta: { requiresAdmin: true }, // 需要管理員權限
  children: [
    {
      path: "",
      name: "admin-dashboard",
      component: () => import("@/components/admin/AdminDashboard.vue"),
      meta: { hideHeader: true, hideFooter: true } // 添加這個元數據
    },
    {
      path: "products",
      name: "admin-products",
      component: () => import("@/components/admin/ProductsPage.vue"),
      meta: { hideHeader: true, hideFooter: true } // 添加這個元數據
    },
    {
      path: "orders",
      name: "admin-orders",
      component: () => import("@/components/admin/OrdersPage.vue"),
      meta: { hideHeader: true, hideFooter: true } // 添加這個元數據
    },
    {
      path: "users",
      name: "admin-users",
      component: () => import("@/components/admin/UsersPage.vue"),
      meta: { hideHeader: true, hideFooter: true } // 添加這個元數據
    },
    {
      path: "lease",
      name: "admin-lease",
      component: () => import("@/components/admin/LeasePage.vue"),
      meta: { hideHeader: true, hideFooter: true } // 添加這個元數據
    },
  ]
}




  //   // 捕捉所有未匹配的路由，導向 404 頁面
  //   {
  //     path: '/:pathMatch(.*)*',
  //     name: 'not-found',
  //     component: () => import('@/components/NotFoundPage.vue')
  //   }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // 平滑滾動到頁面頂部
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, behavior: "smooth" };
    }
  },
});

//  全域前置守衛：已登入者不能進 login/register 頁
router.beforeEach((to, from, next) => {
  const token = Cookies.get("token");
  
  // 已登入者不能進 login/register 頁
  if (token && (to.path === "/login" || to.path === "/register")) {
    next("/");
    return;
  }
  
  // 檢查需要身份驗證的路由
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!token) {
      next({ path: "/login", query: { redirect: to.fullPath } });
      return;
    }
  }
  
  // 檢查需要管理員權限的路由
  if (to.matched.some(record => record.meta.requiresAdmin)) {
    if (!token) {
      next({ path: "/login", query: { redirect: to.fullPath } });
      return;
    }
    
    // 解析JWT token以獲取admin權限
    try {
      // 分割token並獲取payload部分
      const payload = token.split('.')[1];
      // 解碼base64
      const decodedPayload = JSON.parse(atob(payload));
      
      // 檢查admin權限
      if (decodedPayload.admin !== "true") {
        next({ path: "/" }); // 導向首頁或其他適當頁面
        return;
      }
    } catch (error) {
      console.error("Token parsing error:", error);
      next({ path: "/" });
      return;
    }
  }
  
  next(); // 其他情況照常通過
});

export default router;
