<template>
  <div class="app">
    <!-- Header with Navigation -->
    <header class="header" v-if="!$route.meta.hideHeader">
      <div class="container">
        <div class="logo-container">
          <!-- 註記：這裡應該放置 logo 圖片 -->
          <img src="@/assets/image/logo.png" alt="祐強醫療儀器" class="logo" />
          <router-link to="/" class="company-info">
            <h1 class="company-name">祐強醫療儀器有限公司</h1>
            <span class="company-name-en"
              >YoChung Medical Instrument Co., Ltd.</span
            >
          </router-link>
        </div>

        <nav class="navigation">
          <ul class="nav-list">
            <li><router-link to="/" class="nav-link">首頁</router-link></li>
            <li>
              <router-link to="/about" class="nav-link">關於祐強</router-link>
            </li>

            <li>
              <router-link to="/products" class="nav-link"
                >商品專區</router-link
              >
            </li>
            <li>
              <router-link to="/contact" class="nav-link">聯絡客服</router-link>
            </li>

            <li v-if="!isLoggedIn">
              <router-link to="/login" class="nav-link">註冊/登入</router-link>
            </li>
            <li v-else class="nav-dropdown">
              <a class="nav-link" href="#">
                {{ isAdmin ? "控制中心" : "會員中心" }} 
              </a>
              <ul class="dropdown-menu">
                <li>
                  <router-link :to="isAdmin ? '/admin' : '/member'"
                    >前往</router-link
                  >
                </li>
                <li>
                  <a href="#" @click.prevent="logout">登出</a>
                </li>
              </ul>
            </li>
          </ul>
        </nav>

        <button class="mobile-menu-btn" @click="toggleMobileMenu">
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
    </header>

    <!-- Mobile Navigation -->
    <div class="mobile-menu" :class="{ active: mobileMenuOpen }">
      <ul class="mobile-nav-list">
        <li><router-link to="/" @click="closeMobileMenu">首頁</router-link></li>
        <li>
          <router-link to="/about" @click="closeMobileMenu"
            >關於祐強</router-link
          >
        </li>
        <li>
          <router-link to="/products" @click="closeMobileMenu"
            >商品介紹</router-link
          >
        </li>
        <li>
          <router-link to="/contact" @click="closeMobileMenu"
            >聯絡客服</router-link
          >
        </li>
        <li>
          <router-link to="/login" @click="closeMobileMenu"
            >登入</router-link
          >
        </li>
      </ul>
    </div>

    <!-- Main Content Area -->
    <main class="main-content">
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </main>

    <!-- Footer -->
    <footer class="footer" v-if="!$route.meta.hideFooter">
      <div class="container">
        <div class="footer-content">
          <div class="footer-section">
            <h3>祐強寵物醫療</h3>
            <p>專業、溫馨、值得信賴的寵物醫療服務</p>
          </div>

          <div class="footer-section">
            <h3>聯絡我們</h3>
            <p><i class="fas fa-phone"></i> (03) 371-0988</p>
            <p><i class="fas fa-envelope"></i> yochung@rongchun.com.tw</p>
          </div>

          <div class="footer-section">
            <h3>營業時間</h3>
            <p>週一至週五: 9:00 - 18:00</p>
          </div>

          <div class="footer-section">
            <h3>社群媒體</h3>
            <div class="social-links">
              <a
                href="https://www.facebook.com/yochung.tw/?locale=zh_TW"
                target="_blank"
                ><i class="fab fa-facebook-square"></i
              ></a>
              <a href="https://lin.ee/bN5528E" target="_blank"
                ><i class="fab fa-line"></i
              ></a>
            </div>
          </div>
        </div>

        <div class="copyright">
          &copy; {{ new Date().getFullYear() }} 祐強寵物醫療. 版權所有.
        </div>
      </div>
    </footer>
  </div>
</template>

<script src="@/assets/js/app.js"></script>

<style src="@/assets/css/app.css"></style>
